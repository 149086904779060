/* * * * * * * * *  * * * * * * * * *
 * This file is used to add fonts from files
 * * * * * * * * * ** * * * * * * * */
@font-face {
    font-family: nexa;
    src: url("/assets/fonts/Nexa Bold.otf");
}

@font-face {
    font-family: tinkov;
    src: url("/assets/fonts/TinkovRegular.otf");
    font-weight: normal;
}

@font-face {
    font-family: tinkov;
    src: url("/assets/fonts/TinkovLight.otf");
    font-weight: 300;
}

@font-face {
    font-family: tinkov;
    src: url("/assets/fonts/TinkovBold.otf");
    font-weight: bold;
}

@font-face {
    font-family: oldSportAthletic;
    src: url("/assets/fonts/OldSportAthletic.ttf");
    font-weight: bold;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('/assets/fonts/HelveticaNeueLTPro-Md.eot');
    src: url('/assets/fonts/HelveticaNeueLTPro-Md.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HelveticaNeueLTPro-Md.woff2') format('woff2'),
        url('/assets/fonts/HelveticaNeueLTPro-Md.woff') format('woff'),
        url('/assets/fonts/HelveticaNeueLTPro-Md.ttf') format('truetype'),
        url('/assets/fonts/HelveticaNeueLTPro-Md.svg#HelveticaNeueLTPro-Md') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('/assets/fonts/HelveticaNeueLTPro-Hv.eot');
    src: url('/assets/fonts/HelveticaNeueLTPro-Hv.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HelveticaNeueLTPro-Hv.woff2') format('woff2'),
        url('/assets/fonts/HelveticaNeueLTPro-Hv.woff') format('woff'),
        url('/assets/fonts/HelveticaNeueLTPro-Hv.ttf') format('truetype'),
        url('/assets/fonts/HelveticaNeueLTPro-Hv.svg#HelveticaNeueLTPro-Hv') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('/assets/fonts/HelveticaNeueLTPro-Th.eot');
    src: url('/assets/fonts/HelveticaNeueLTPro-Th.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HelveticaNeueLTPro-Th.woff2') format('woff2'),
        url('/assets/fonts/HelveticaNeueLTPro-Th.woff') format('woff'),
        url('/assets/fonts/HelveticaNeueLTPro-Th.ttf') format('truetype'),
        url('/assets/fonts/HelveticaNeueLTPro-Th.svg#HelveticaNeueLTPro-Th') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('/assets/fonts/HelveticaNeueLTPro-Cn.eot');
    src: url('/assets/fonts/HelveticaNeueLTPro-Cn.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HelveticaNeueLTPro-Cn.woff2') format('woff2'),
        url('/assets/fonts/HelveticaNeueLTPro-Cn.woff') format('woff'),
        url('/assets/fonts/HelveticaNeueLTPro-Cn.ttf') format('truetype'),
        url('/assets/fonts/HelveticaNeueLTPro-Cn.svg#HelveticaNeueLTPro-Cn') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('/assets/fonts/HelveticaNeueLTPro-Blk.eot');
    src: url('/assets/fonts/HelveticaNeueLTPro-Blk.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HelveticaNeueLTPro-Blk.woff2') format('woff2'),
        url('/assets/fonts/HelveticaNeueLTPro-Blk.woff') format('woff'),
        url('/assets/fonts/HelveticaNeueLTPro-Blk.ttf') format('truetype'),
        url('/assets/fonts/HelveticaNeueLTPro-Blk.svg#HelveticaNeueLTPro-Blk') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('/assets/fonts/HelveticaNeueLTPro-Lt.eot');
    src: url('/assets/fonts/HelveticaNeueLTPro-Lt.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HelveticaNeueLTPro-Lt.woff2') format('woff2'),
        url('/assets/fonts/HelveticaNeueLTPro-Lt.woff') format('woff'),
        url('/assets/fonts/HelveticaNeueLTPro-Lt.ttf') format('truetype'),
        url('/assets/fonts/HelveticaNeueLTPro-Lt.svg#HelveticaNeueLTPro-Lt') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('/assets/fonts/HelveticaNeueLTPro-Bd.eot');
    src: url('/assets/fonts/HelveticaNeueLTPro-Bd.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HelveticaNeueLTPro-Bd.woff2') format('woff2'),
        url('/assets/fonts/HelveticaNeueLTPro-Bd.woff') format('woff'),
        url('/assets/fonts/HelveticaNeueLTPro-Bd.ttf') format('truetype'),
        url('/assets/fonts/HelveticaNeueLTPro-Bd.svg#HelveticaNeueLTPro-Bd') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('/assets/fonts/HelveticaNeueLTPro-UltLt.eot');
    src: url('/assets/fonts/HelveticaNeueLTPro-UltLt.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HelveticaNeueLTPro-UltLt.woff2') format('woff2'),
        url('/assets/fonts/HelveticaNeueLTPro-UltLt.woff') format('woff'),
        url('/assets/fonts/HelveticaNeueLTPro-UltLt.ttf') format('truetype'),
        url('/assets/fonts/HelveticaNeueLTPro-UltLt.svg#HelveticaNeueLTPro-UltLt') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

