.back-office-panel, .website {
    select.cg,
    input.cg,
    textarea.cg {
        width: 100%;
        border: 1px solid #e0e0db;
        font-weight: 300;
        border-radius: 4px;
        padding: 2px 6px;
        height: 31px;
        font-size: 14px;
        margin: 0;
    }

    textarea.cg {
        height: auto !important;
        font-weight: 300;
    }

    input.cg:focus,
    select.cg:focus,
    textarea.cg:focus {
        border: 1.8px solid var(--ap-secondary);
        box-shadow: none;
        outline: none;
    }

    form .cg-input-group {
        margin-bottom: 5px;
    }

    .cg-input-group {
        // margin-bottom: 5px;

        label {
            font-size: 12px;
            display: block;
            width: 100%;
            color: var(--ap-bg-primary-contrast);
            font-weight: 300;
            margin: 0;
            padding: 0;
        }

        .group {
            display: flex;
            width: 100%;
        }
    }

    .cg-input-group .group .pre + input,
    .cg-input-group .group .pre + select {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }

    .cg-input-group .group input.post,
    .cg-input-group .group select.post {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    .cg-input-group .group .pre button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .cg-input-group .group .post button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    /* Input Errors */
    .ng-invalid.ng-touched,
    .ng-invalid.ng-dirty,
    .ng-invalid.form-submitted {
        border-color: lightcoral !important;
    }

    .errors p {
        margin: 0;
        font-size: 13px;
        color: #f12112;
        font-weight: 500;
    }
}
