.back-office-panel {
    .cg-badge {
        display: inline-block;
        position: relative;
        text-align: center;
        border-radius: 12px;
        color: #fff;
        padding: 1px 0;
        padding-left: 18px;
        padding-right: 5px;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 400;

        @each $style in $styles {
            &.#{$style} {
                background: var(--ap-#{$style});
            }

            &::before {
                content: '';
                width: 8px;
                height: 8px;
                border: 2px solid #fff;
                position: absolute;
                top: 6px;
                left: 8px;
                border-radius: 50%;
            }
        }

        .lg {
            border-radius: 14px;
            width: auto;
            padding: 3px 15px;
            padding-left: 25px;
            font-size: 14px;
            font-weight: 400;

            &::before {
                width: 10px;
                height: 10px;
                top: 8px;
            }
        }
    }
}

