.back-office-panel {
    /* Customize the label (the container) */
    .cg.checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 0;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 30px;
    }

    /* Hide the browser's default checkbox */
    .cg.checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .cg.checkbox .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border: 1px solid var(--ap-bg-secondary);
        border-radius: 3px;
    }

    /* On mouse-over, add a grey background color */
    .cg.checkbox:hover input ~ .checkmark {
        background-color: var(--ap-bg-secondary);
    }

    /* When the checkbox is checked, add a blue background */
    .cg.checkbox input:checked ~ .checkmark {
        // background-color: #2196f3;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .cg.checkbox .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .cg.checkbox input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .cg.checkbox .checkmark:after {
        left: 8px;
        top: 2px;
        width: 7px;
        height: 15px;
        border: solid var(--ap-bg-secondary);
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .cg.checkbox-container {
        display: flex;
    }
    .cg.checkbox-container.text-bottom, .cg.checkbox-container.text-top {
        flex-direction: column;
        align-items: center;
    }
    .cg.checkbox-container span.text {
        font-size: 16px;
        color: var(--ap-bg-primary-contrast);
    }

    @each $style in $styles {
        .cg.checkbox.#{$style} .checkmark:after {
            border-color: var(--ap-#{$style});
        }
    }
}
