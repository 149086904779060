.back-office-panel {
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 7px;
        background-color: var(--ap-bg-primary);
    }

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        background-color: var(--ap-bg-primary);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 7px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: var(--ap-bg-secondary);
    }
}
.website {
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 7px;
        background-color: var(--ap-bg-primary);
    }

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        background-color: var(--ap-bg-primary);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 7px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: var(--ap-bg-secondary);
    }
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 7px;
    background-color: var(--ap-bg-primary);
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: var(--ap-bg-primary);
}

::-webkit-scrollbar-thumb {
    border-radius: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: var(--ap-bg-secondary);
}
/* For friefox */
* {
    //scrollbar-color: rgb(210,210,210) rgb(46,54,69) !important;
    //scrollbar-color: #555 transparent!important;
    //scrollbar-width: thin !important;
}
