$f-primary: 'Helvetica Neue LT Pro';
    // $text-color: #616163;
    $bodybg: #D0D4FB;
    $darkbg: #000;
    $bluebg: #4C5AE5;
    $txtlit: #e4e4e4;
    $shadow: 0px 0px 3px 1px rgb(116 116 111 / 25%);

    @mixin min($size) {
        @media (min-width: $size) {
            @content;
        }
    }
    
    @mixin max($size) {
        @media (max-width: $size) {
            @content;
        }
    }
    
    @mixin min-max($min, $max) {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }